import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useSearchParams, useNavigate, Link } from 'react-router-dom';
import Home from './components/home/home';
import NavBar from './components/navbar/navbar';
import './App.css'
import ProgressBar from './components/progressbar/progressbar';
import LegalStatuts from './components/form/legal-statuts/legal-statuts';
import Associates from './components/tax-assistant/associates/associates';
import ActivityType from './components/tax-assistant/activity-type/activity-type';
import Remunerated from './components/tax-assistant/remunerated/remunerated';
import CaEstimation from './components/tax-assistant/ca-estimation/ca-estimation';
import Charges from './components/tax-assistant/charges/charges';
import Result from './components/tax-assistant/result/result';
import ContactInformations from './components/form/contact-informations/contact-informations';
import AlreadySetUpBusiness from './components/form/already-set_up-business/already-set_up-business';
import WorkAs from './components/form/work-as/work-as';
import DealName from './components/form/dealname/dealname';
import RegisterYourTrademark from './components/form/register-your-trademark/register-your-trademark';
import Activity from './components/form/activity/activity';
import CraftActivity from './components/form/craft-activity/craft-activity';
import SelfEmployed from './components/form/self-employed/self-employed';
import Domiciliation from './components/form/domiciliation/domiciliation';
import Bank from './components/form/bank/bank';
import Accounting from './components/form/accounting/accounting';
import Recap from './components/form/recap/recap';
import LegalPack from './components/form/legal-pack/legal-pack';
import MerchantRecap from './components/form/merchant-recap/merchant-recap';
import { Helmet } from 'react-helmet';
import { Analytics } from '@vercel/analytics/react';
import { ref, get, set, onValue, off } from 'firebase/database';
import { db } from './config/firebase';
import Checkout from './components/form/checkout/checkout';
import Success from './components/form/success/success';
import LastPage from './components/last_page/last_page';
import PaymentSuccess from './components/form/payment-success/payment-success';
import Contract from './components/form/contract/contract';
import Direction from './components/direction/direction';
import NewCompany from './components/new_company/new_company';
import { SpeedInsights } from "@vercel/speed-insights/react"

export const UserContext = createContext(null);


function getValueByPath(obj, path) {
  const keys = path.split('.');
  let result = obj;

  for (const key of keys) {
    if (result && result.hasOwnProperty(key)) {
      result = result[key];
    } else {
      return undefined;
    }
  }
  return result;
}

function RouterComponent({ setCurrentStep }) {
  const { company, setCompany, handleChange, getPathIndex } = useContext(UserContext);
  const location = useLocation();
  const [parameters] = useSearchParams();
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(false);

  // const { last_page, ...formWithoutLastPage } = company.form;

  const updateCompany = useCallback((updates) => {
    setCompany((prevState) => ({
      ...prevState,
      ...updates
    }));
  }, [setCompany]);

  const handleRouteChange = useCallback(() => {
    document.querySelectorAll('.multiform').forEach((global_question) => {
      // console.log(global_question, 'querySelectorAll');
      const question = global_question.getAttribute('question');
      if(question !== null) {
        const value = getValueByPath(company, question);
        if(document.querySelector(`.multiform[question="${question}"] .big-button.pre-choice`) !== null) {
          document.querySelector(`.multiform[question="${question}"] .big-button.pre-choice`).classList.remove('pre-choice');
        }
        if(document.querySelector(`.multiform[question="${question}"] .big-button[choice="${value}"]`) !== null) {
          document.querySelector(`.multiform[question="${question}"] .big-button[choice="${value}"]`).classList.add('pre-choice');
        }
      }
    });
  }, [company.form]);// Potentiel source de bug

  useEffect(() => {
    handleRouteChange();
  }, [location, handleRouteChange]);
  const pathToIndex = {
    "/": 0,
    "/direction": 0,
    "/tax-assistant/associates": 0,
    "/tax-assistant/activity-type": 0,
    "/tax-assistant/remunerated": 0,
    "/tax-assistant/ca-estimation": 0,
    "/tax-assistant/charges": 0,
    "/tax-assistant/result": 0,
    "/form/legal-statuts": 0,
    "/form/contact-informations": 0,
    "/form/already-set_up-business": 0,
    "/form/work-as": 0,
    "/form/dealname": 0,
    "/form/register-your-trademark": 0,
    "/form/activity": 0,
    "/form/craft-activity": 0,
    "/form/self-employed": 0,
    "/form/domiciliation": 1,
    "/form/bank": 1,
    "/form/accounting": 1,
    "/form/recap": 2,
    "/form/legal-pack": 2,
    "/form/merchant-recap": 2,
    "/form/checkout": 2,
    "/form/payment-success": 2,
    "/form/contract": 2,
    "/form/success": 3
  }

  useEffect(() => {
    setCurrentStep(pathToIndex[location.pathname] || 0);
    window.scrollTo({top: 0});
    handleChange({
      'name': 'form.last_page',
      'value': location.pathname
    });
  }, [location, setCurrentStep])
  useEffect(() => {
    if (company.form?.transaction_id && !fetching) {
      // console.log('Initialisation Firebase, transaction_id:', company.form?.transaction_id);
      const fetchData = async () => {
        const dbRef = ref(db, '/self-onboarding/form/' + company.form?.transaction_id);
        try {
          const snapshot = await get(dbRef);
          if (snapshot.exists()) {
            // console.log('Données récupérées:', snapshot.val());
            setCompany(snapshot.val());
          } else {
          }
        } catch (error) {
          console.error('Erreur :', error);
        } 
      };
      fetchData();
    }
  }, [company.form?.transaction_id, setCompany, fetching]);
  useEffect(() => {
    if (company.form?.transaction_id && getPathIndex(location.pathname) >= getPathIndex(company.form?.last_page) && getPathIndex(location.pathname) < 25 && getPathIndex(location.pathname) !== 0) {
      // console.log('Mise à jour en cours');
      const updateFirebase = async () => {
        const dbRef = ref(db, '/self-onboarding/form/' + company.form?.transaction_id);
        try {
          const snapshot = await get(dbRef);
          const data = {
            ...company,
            form: {
              ...company.form,
              last_page: location.pathname
            },
            updatedAt: new Date().toISOString()
          };
          if (snapshot.exists()) {
            await set(dbRef, data);
          } else {
            data.createdAt = new Date().toISOString();
            await set(dbRef, data);
          }
        } catch (error) {
          console.error('Erreur:', error);
        }
      };
      updateFirebase();
    }
  }, [company, parameters, location.pathname]);

  useEffect(() => {
    if (company.form?.transaction_id === null) {
      updateCompany({
        form: {
          ...company.form,
          transaction_id: null
        }
      });
    }
    if (company.form?.last_page !== location.pathname && company.form?.last_page) {
      // navigate(company.form?.last_page);
      // console.log('Dernière page reconnue, proposition de navigation', company.form?.last_page);
    } else {
      // console.log('Aucune dernière page renseignée', company.form?.last_page !== location.pathname, company.form?.last_page);
    }
  }, []);

  useEffect(() => {
    // console.log(getPathIndex(location.pathname), location.pathname, 'currentStep')
    if (company.form?.transaction_id && getPathIndex(location.pathname) <= 23) {
      const dbRef = ref(db, '/payments/' + company.form?.transaction_id);

      const unsubscribe = onValue(dbRef, async (snapshot) => {
        // console.log('Snapshot:', snapshot);
        if (snapshot.exists() && snapshot.val().payment_status === 'succeeded') {
          // console.log('Données récupérées:', snapshot.val());
          await setCompany({ ...company, payment: {...company.payment, payment_method: snapshot.val().payment_method, payment_id: snapshot.val().payment_id, payment_status: snapshot.val().payment_status, installment_count: snapshot.val().installment_count } });
          navigate('/form/contract');
        } else {
          // console.log('Pas de données de paiement trouvées');
        }
      }, (error) => {
        console.error('Erreur de lecture de Firebase:', error);
      });

      // Cleanup function pour se désabonner de l'écouteur quand le composant se démonte ou transaction_id change
      return () => off(dbRef, 'value', unsubscribe);
    }  else if(company.form?.transaction_id && getPathIndex(location.pathname) <= 24) {
      const dbRef = ref(db, '/contract/' + company.form?.transaction_id);

      const unsubscribe = onValue(dbRef, async (snapshot) => {
        // console.log('Snapshot:', snapshot);
        if (snapshot.exists() && snapshot.val().contract_status === 'signed') {
          // console.log('Données récupérées:', snapshot.val());
          await setCompany({ ...company, contract: {...company.contract, contract_id: snapshot.val().contract_id, contract_status: snapshot.val().contract_status, is_signed: true } });
          navigate('/form/success');
        } else {
          // console.log('Pas de données de contrat trouvées');
        }
      }, (error) => {
        console.error('Erreur de lecture de Firebase:', error);
      });

      // Cleanup function pour se désabonner de l'écouteur quand le composant se démonte ou transaction_id change
      return () => off(dbRef, 'value', unsubscribe);
    }
  }, [company.form?.transaction_id, navigate, location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:special" element={<Home />} />
      <Route path="/home/direction" element={<Direction />} />
      <Route path="/tax-assistant/associates" element={<Associates />} />
      <Route path="/tax-assistant/activity-type" element={<ActivityType />} />
      <Route path="/tax-assistant/remunerated" element={<Remunerated />} />
      <Route path="/tax-assistant/ca-estimation" element={<CaEstimation />} />
      <Route path="/tax-assistant/charges" element={<Charges />} />
      <Route path="/tax-assistant/result" element={<Result />} />
      <Route path="/form/legal-statuts" element={<LegalStatuts />} />
      <Route path="/form/legal-statuts/:legal_statut" element={<LegalStatuts />} />
      <Route path="/form/contact-informations" element={<ContactInformations />} />
      <Route path="/form/already-set_up-business" element={<AlreadySetUpBusiness />} />
      <Route path="/form/work-as" element={<WorkAs />} />
      <Route path="/form/dealname" element={<DealName />} />
      <Route path="/form/register-your-trademark" element={<RegisterYourTrademark />} />
      <Route path="/form/activity" element={<Activity />} />
      <Route path="/form/craft-activity" element={<CraftActivity />} />
      <Route path="/form/self-employed" element={<SelfEmployed />} />
      <Route path="/form/domiciliation" element={<Domiciliation />} />
      <Route path="/form/bank" element={<Bank />} />
      <Route path="/form/accounting" element={<Accounting />} />
      <Route path="/form/recap" element={<Recap />} />
      <Route path="/form/legal-pack" element={<LegalPack />} />
      <Route path="/form/merchant-recap" element={<MerchantRecap />} />
      <Route path="/form/checkout" element={<Checkout />} />
      <Route path="/form/checkout/:payment_statut/:transaction_id" element={<Checkout />} />
      <Route path="/form/payment-success/:payment_method" element={<PaymentSuccess />} />
      <Route path="/form/contract" element={<Contract />} />
      <Route path="/form/success" element={<Success />} />
      <Route path="/form/success/:payment_statut/:transaction_id" element={<Success />} />
    </Routes>
  );
}

function App() {
  const [company, setCompany ] = useState(() => {
    const infos = localStorage.getItem('company');
    return infos ? JSON.parse(infos) : {
      'assistant': {
        "solo_associate": "", // true, false
        "activity_type": "", // service_provision, sales_of_goods, service_provision_liberal, service_provision_craftsman
        "remunerated": "", // pay_slip, immediate, dividends, complementary_salaried_employment
        "ca_estimation": "", // - de 180 000€, - de 77 700€, + de 77 700€, + de 180 000€
        "charges": "" // - de 34%, + de 34%
      }, 
      'form': {
        'legal_statuts': '', // SAS | SASU, SARL | EURL, Auto-entrepeneur, Entreprise individuelle, Association
        'contact': {
          "firstname": "",
          "lastname": "",
          "email": "",
          "phone": "",
          "google": ""
        },
        "last_page": null,
        "fastlane": false,
        "transaction_id": "",
        "contact_id": "",
        "already-set_up-business": "", // self-employed,  already_set_up, never
        "work_as": "", // Entrepreneur, Salarié, Demandeur d'emploi, Étudiant,
        "dealname": "",
        "activity": "", // E-commerce, Import / Export, Alimentation / Restauration, BTP / Aménagement, Conseils / Consultant, Logiciel SaaS / Applications, Transport, Autres
        "other_activity": "",
        "craft_activity": null, // true, false
        "self_employed": null, // true, false
        "domiciliation": null, // true, false
        "bank": null, // true, false
        "accounting": {
          "contract": "monthly", // annually, monthly
        },
        "legal_pack": "", // Premium & Réussite, Standard, Débutant & Seul
        "promo_code_value": "",
        "promo_code_pourcentage": "",
        "upsell": {
          "deregistration": false, // true, false
          "bylaws_word": false, // true, false
          "register_your_trademark": null, // true, false
          "kbis_by_post": false, // true, false
          "canvassing": false, // true, false
          "logo": false, // true, false
          "brand_identity": false, // true, false
          "business_card": false, // true, false
          "flyers": false, // true, false
          "domain_name_protection": false, // true, false
          "computer_scientict": false, // true, false
          "mail_signature": false, // true, false
          "community_management": false, // true, false
          "social_community_management": {
            'instagram': true,
            'facebook': true,
            'tiktok': false,
            'twitter': false,
            'snapchat': false,
            'pinterest': false,
            'linkedin': false
          }
        }
      },
      "hubspot_product": {
        "one_off": {
          legal_pack: {
            product: null,
            line_item: null
          },
          register_your_trademark: {
            product: null,
            line_item: null
          },
          domiciliation: {
            product: null,
            line_item: null
          },
          bank: {
            product: null,
            line_item: null
          },
          bylaws_word: {
            product: null,
            line_item: null
          },
          canvassing: {
            product: null,
            line_item: null
          },
          logo: {
            product: null,
            line_item: null
          },
          brand_identity: {
            product: null,
            line_item: null
          },
          business_card: {
            product: null,
            line_item: null
          },
          flyers: {
            product: null,
            line_item: null
          },
          domain_name_protection: {
            product: null,
            line_item: null
          },
          computer_scientict: {
            product: null,
            line_item: null
          },
          mail_signature: {
            product: null,
            line_item: null
          },
          community_management: {
            product: null,
            line_item: null
          },
          deregistration: {
            product: null,
            line_item: null
          },
          kbis_by_post: {
            product: null,
            line_item: null
          }
        },
        "subscriptions": {
          accounting: {
            product: null,
            line_item: null
          },
          ag: {
            product: null,
            line_item: null
          }
        }
      },
      "shoppingCart": {
        legal_pack: null, // 229, 169, 0
        court_fees: 259.39,
        accounting: 0, // 89, 119, 39, 348
        kbis_by_post: 0, // 0, 19.90
        register_your_trademark: 0, // 0, 269
        bylaws_word: 0, // 0, 9.90
        canvassing: 0, // 0, 29.9
        logo: 0, // 0, 149
        brand_identity: 0, // 0, 139
        business_card: 0, // 0, 99
        flyers: 0, // 0, 99
        domain_name_protection: 0, // 0, 199
        computer_scientict: 0, // 0, 399
        mail_signature: 0, // 0, 99
        community_management: 0, // 0
        general_meeting: null, // 0, 119
        deregistration: 0 // 0, 169
      },
      "sepa": {
        "billing_request_id": null
      },
      "payment": {
        "payment_solution": null,
        "payment_id": null
      },
      "contract": {
        "id": null,
        "participant_id": null,
        "url": null,
        "is_signed": false
      }
    };
  })

  const [shoppingCart, setShoppingCart ] = useState(() => {
    const infos = localStorage.getItem('shoppingCart');
    return infos ? JSON.parse(infos) : {
    };
    // legal_pack: 0, // 229, 169, 0
    // court_fees: 259.39,
    // accounting: 0, // 89, 119, 39, 348
    // kbis_by_post: 0, // 0, 19.90
    // register_your_trademark: 0, // 0, 269
    // bylaws_word: 0, // 0, 9.90
    // canvassing: 0, // 0, 29.9
    // logo: 0, // 0, 149
    // brand_identity: 0, // 0, 139
    // business_card: 0, // 0, 99
    // flyers: 0, // 0, 99
    // domain_name_protection: 0, // 0, 199
    // computer_scientict: 0, // 0, 399
    // mail_signature: 0, // 0, 99
    // community_management: 0, // 0
    // general_meeting: 0, // 0, 119
    // deregistration: 0 // 0, 169
  });

  const products = {
    subscriptions: {
      annually: {
        accounting: {
          ugs: "ABO société commerciale Annuel Glissant",
          hs_product_id: 1588126665
        },
        ag: {
          ugs: "ABO AG (annuel)",
          hs_product_id: 1290922450
        }
      },
      monthly: {
        accounting: {
          ugs: "ABO société commerciale mensuel",
          hs_product_id: 1379550667
        },
        ag: {
          ugs: "ABO AG (annuel)",
          hs_product_id: 1290922450
        }
      }
    },
    one_off: {
      legal_pack: [
        {
          ugs: 'PC - legal_pack-1', //Premium & Réussite, Standard, Débutant & Seul
          hs_product_id: 3295289071
        },
        {
          ugs: "PC - legal_pack-2",
          hs_product_id: 3294325729
        },
        {
          ugs: "PC - legal_pack-3",
          hs_product_id: 3295289074
        }
      ],
      register_your_trademark: {
        ugs: 'PC - register_your_trademark',
        hs_product_id: 3294325460
      },
      domiciliation: {
        ugs: 'PC - domiciliation',
        hs_product_id: 3294325459
      },
      bank: {
        ugs: 'PC - Shine création',
        hs_product_id: 2555378124
      },
      bylaws_word: {
        ugs: 'PC - bylaws_word',
        hs_product_id: 3295746253
      },
      canvassing: {
        ugs: "PC - canvassing", 
        hs_product_id: 3295746255
      },
      logo: {
        ugs: "PC - logo", 
        hs_product_id: 3294325468
      },
      brand_identity: {
        ugs: "PC - brand_identity", 
        hs_product_id: 3295728328
      },
      business_card: {
        ugs: "PC - business_card", 
        hs_product_id: 3294325470
      },
      flyers: {
        ugs: "PC - flyers", 
        hs_product_id: 3295746263
      },
      domain_name_protection: {
        ugs: "PC - domain_name_protection", 
        hs_product_id: 3295768512
      },
      computer_scientict: {
        ugs: "PC - computer_scientict", 
        hs_product_id: 3295728330
      },
      mail_signature: {
        ugs: "PC - mail_signature", 
        hs_product_id: 3295746264
      },
      community_management: {
        ugs: "PC - community_management", 
        hs_product_id: 3295768508
      },
      deregistration: {
        ugs: "PC - deregistration", 
        hs_product_id: 3295728323
      },
      kbis_by_post: {
        ugs: "PC - kbis_by_post", 
        hs_product_id: 3295768310
      }
    }
  }

  const updateShoppingCart = (key, value) => {
    setShoppingCart(prev => ({
        ...prev,
        [key]: value
    }));
    // console.log(shoppingCart, 'shoppingCart updated');
  };

  const [currentStep, setCurrentStep] = useState(0);

  const handleChange = (input) => {
    // Détecter si l'input est un événement ou un objet de mise à jour directe
    const { name, value: inputValue, type, checked } = input.target || input;
    const stringToBoolean = (elem => {
      if(typeof elem == "boolean" || typeof elem == "number" || typeof elem == "object") {
        return elem;
      } else {
        switch(elem.toLowerCase()) {
          case 'true':
            return true;
          case 'false':
            return false;
          default:
            return elem;
        }
      }
    });

    setCompany(prevState => {
      // Déterminer la valeur basée sur le type d'entrée
      const value = type === 'checkbox' ? checked : inputValue;
      // Chemin d'accès du nom splité pour gérer les sous-objets
      const nameParts = name.split('.');
      // Fonction récursive pour mettre à jour l'état imbriqué
      function updateNestedState(prev, parts, val) {
        const key = parts[0];
        // Si c'est la dernière clé, met à jour la valeur
        if (parts.length === 1) {
          // console.log(val, 'modification de la valeur');
          // console.log(stringToBoolean(val), 'Modification de la valeur dans company');
          return { ...prev, [key]: stringToBoolean(val) };
        }
        // Sinon, processus récursif pour les sous-objets
        return { ...prev, [key]: updateNestedState(prev[key] || {}, parts.slice(1), val) };
      }
      return updateNestedState(prevState, nameParts, value);
    });
  };

  useEffect(() => {
    localStorage.setItem('company', JSON.stringify(company));
  }, [company]);

  const totalPathToIndex = {
    "/": 0,
    "/direction": 0,
    "/tax-assistant/associates": 1,
    "/tax-assistant/activity-type": 2,
    "/tax-assistant/remunerated": 3,
    "/tax-assistant/ca-estimation": 4,
    "/tax-assistant/charges": 5,
    "/tax-assistant/result": 6,
    "/form/legal-statuts": 7,
    "/form/legal-statuts/SASU": 7,
    "/form/contact-informations": 8,
    "/form/self-employed": 9, // fastlane
    "/form/already-set_up-business": 10,// fastlane
    "/form/work-as": 11, // fastlane
    "/form/dealname": 12,
    "/form/register-your-trademark": 13, // fastlane
    "/form/activity": 14, // fastlane
    "/form/craft-activity": 15, // fastlane
    "/form/domiciliation": 16, // fastlane
    "/form/bank": 17, // fastlane
    "/form/accounting": 18,
    "/form/recap": 19,
    "/form/legal-pack": 20,
    "/form/merchant-recap": 21,
    "/form/checkout": 22,
    "/form/payment-success": 23,
    "/form/payment-success/alma": 23,
    "/form/payment-success/paypal": 23,
    "/form/payment-success/sepa": 23,
    "/form/contract": 24,
    "/form/success": 25,
    "dynamic": (path) => {
      // Gérer les cas de chemins dynamiques avec ID
      const dynamicRegex = /^\/\d+$/;
      if (dynamicRegex.test(path)) {
        return 0; // L'indice pour les URLs avec un ID
      }
      return -1; // Un indice qui indique une non-correspondance
    }
  }
  const getPathIndex = (path) => {
    if (totalPathToIndex[path] !== undefined) {
      return totalPathToIndex[path];
    } else if (typeof totalPathToIndex['dynamic'] === 'function') {
      return totalPathToIndex['dynamic'](path);
    }
    return -1; // Fallback si aucune correspondance n'est trouvée
  }

  useEffect(() => {
    // console.log(company.form?.accounting.contract, 'company.form?.accounting.contract');
    if(company.form?.accounting?.contract && company.form?.legal_statuts && (company.form?.fastlane || !company.form?.fastlane) ) {
      if(company.form?.accounting.contract === 'annually') {
        const value = () => {
          if(company.form?.legal_statuts === 'Auto-entrepreneur') {
            if(company.form?.fastlane) {
              return 348;
            } else {
              return 468;
            }
          } else {
            if(company.form?.fastlane) {
              return 588;
            } else {
              return 1068;
            }
          }
        }
        handleChange({'name': 'shoppingCart.accounting', 'value': value()})
        if(company.form?.legal_statuts !== 'Auto-entrepreneur') {
          // handleChange({'name': 'shoppingCart.general_meeting', 'value': 0})
          handleChange({'name': 'shoppingCart.general_meeting', 'value': null})
        } else {
          // Si AG existe alors deleteLineItem
          const path = 'hubspot_product.subscriptions.ag';
          if(company.hubspot_product?.subscriptions?.ag?.line_item) {
            deleteLineItem(company.hubspot_product.subscriptions.ag.line_item, path);
          }
          handleChange({'name': 'shoppingCart.general_meeting', 'value': null})
        }
      } else { // Mensuel
        const value = () => {
          if(company.form?.legal_statuts === 'Auto-entrepreneur') {
            if(company.form?.fastlane) {
              return 49;
            } else {
              return 49;
            }
          } else {
            if(company.form?.fastlane) {
              return 49;
            } else {
              return 89;
            }
          }
        }
        handleChange({'name': 'shoppingCart.accounting', 'value': value()})
        // if(company.form?.legal_statuts !== 'Auto-entrepreneur') {
        //   // handleChange({'name': 'shoppingCart.general_meeting', 'value': 119})
        //   handleChange({'name': 'shoppingCart.general_meeting', 'value': null})
        // } else {
        //   // Si AG existe alors deleteLineItem
        //   const path = 'hubspot_product.subscriptions.ag';
        //   deleteLineItem(company.hubspot_product.subscriptions.ag.line_item, path);
        //   handleChange({'name': 'shoppingCart.general_meeting', 'value': null})
        // }
      };
    }
  }, [company.form?.accounting?.contract, company.form?.legal_statuts, company.form?.fastlane]);

  useEffect(() => {
    if(company.form?.legal_pack === "Premium & Réussite") {
      handleChange({'name': 'shoppingCart.legal_pack', 'value': company.form?.fastlane ? 149 : 229});
    } else if(company.form?.legal_pack === "Standard") {
      handleChange({'name': 'shoppingCart.legal_pack', 'value': company.form?.fastlane ? 99 : 169});
    } else if(company.form?.legal_pack === "Débutant & Seul") {
      handleChange({'name': 'shoppingCart.legal_pack', 'value': 0});
    }
  }, [company.form?.legal_pack]);

  useEffect(() => {
    if(company.form?.upsell?.register_your_trademark) {
      // setShoppingCart(prevCart => ({...prevCart, register_your_trademark: 269}));
      handleChange({'name': 'shoppingCart.register_your_trademark', 'value': 269});
    } else {
      // setShoppingCart(prevCart => ({...prevCart, register_your_trademark: 0}));
      handleChange({'name': 'shoppingCart.register_your_trademark', 'value': 0});
    }
  }, [company.form?.upsell?.register_your_trademark]);

  useEffect(() => {
    if(company.form?.domiciliation && company.form?.domiciliation !== false) {
      handleChange({'name': 'shoppingCart.domiciliation', 'value': true});
    } else {
      handleChange({'name': 'shoppingCart.domiciliation', 'value': false});
    }
  }, [company.form?.domiciliation]);

  useEffect(() => {
    if(company.form?.bank && company.form?.bank !== false) {
      handleChange({'name': 'shoppingCart.bank', 'value': true});
    } else {
      handleChange({'name': 'shoppingCart.bank', 'value': false});
    }
  }, [company.form?.bank]);

  useEffect(() => {
    if(company.form?.upsell?.deregistration && company.form?.upsell?.deregistration !== false) {
      // setShoppingCart(prevCart => ({...prevCart, deregistration: 169}));
      handleChange({'name': 'shoppingCart.deregistration', 'value': 169});
    } else {
      // setShoppingCart(prevCart => ({...prevCart, deregistration: 0}));
      handleChange({'name': 'shoppingCart.deregistration', 'value': 0});
    }
  }, [company.form?.upsell?.deregistration]);

  useEffect(() => {
    if(company.form?.upsell?.bylaws_word && company.form?.upsell?.bylaws_word !== false) {
      // setShoppingCart(prevCart => ({...prevCart, bylaws_word: 169}));
      handleChange({'name': 'shoppingCart.bylaws_word', 'value': 19.9});
    } else {
      // setShoppingCart(prevCart => ({...prevCart, bylaws_word: 0}));
      handleChange({'name': 'shoppingCart.bylaws_word', 'value': 0});
    }
  }, [company.form?.upsell?.bylaws_word]);

  useEffect(() => {
    if(company.form?.upsell?.kbis_by_post && company.form?.upsell?.kbis_by_post !== false) {
      // setShoppingCart(prevCart => ({...prevCart, kbis_by_post: 169}));
      handleChange({'name': 'shoppingCart.kbis_by_post', 'value': 9.9});
    } else {
      // setShoppingCart(prevCart => ({...prevCart, kbis_by_post: 0}));
      handleChange({'name': 'shoppingCart.kbis_by_post', 'value': 0});
    }
  }, [company.form?.upsell?.kbis_by_post]);

  useEffect(() => {
    if(company.form?.upsell?.canvassing && company.form?.upsell?.canvassing !== false) {
      // setShoppingCart(prevCart => ({...prevCart, canvassing: 169}));
      handleChange({'name': 'shoppingCart.canvassing', 'value': 29.9});
    } else {
      // setShoppingCart(prevCart => ({...prevCart, canvassing: 0}));
      handleChange({'name': 'shoppingCart.canvassing', 'value': 0});
    }
  }, [company.form?.upsell?.canvassing]);

  useEffect(() => {
    if(company.form?.upsell?.community_management && company.form?.upsell?.community_management !== false) {
      // setShoppingCart(prevCart => ({...prevCart, community_management: 169}));
      handleChange({'name': 'shoppingCart.community_management', 'value': 169});
    } else {
      // setShoppingCart(prevCart => ({...prevCart, community_management: 0}));
      handleChange({'name': 'shoppingCart.community_management', 'value': 0});
    }
  }, [company.form?.upsell?.community_management]);

  useEffect(() => {
    if(company.form?.upsell?.logo && company.form?.upsell?.logo !== false) {
      // setShoppingCart(prevCart => ({...prevCart, logo: 169}));
      handleChange({'name': 'shoppingCart.logo', 'value': 149});
    } else {
      // setShoppingCart(prevCart => ({...prevCart, logo: 0}));
      handleChange({'name': 'shoppingCart.logo', 'value': 0});
    }
  }, [company.form?.upsell?.logo]);

  useEffect(() => {
    if(company.form?.upsell?.brand_identity && company.form?.upsell?.brand_identity !== false) {
      // setShoppingCart(prevCart => ({...prevCart, brand_identity: 169}));
      handleChange({'name': 'shoppingCart.brand_identity', 'value': 99});
    } else {
      // setShoppingCart(prevCart => ({...prevCart, brand_identity: 0}));
      handleChange({'name': 'shoppingCart.brand_identity', 'value': 0});
    }
  }, [company.form?.upsell?.brand_identity]);

  useEffect(() => {
    if(company.form?.upsell?.business_card && company.form?.upsell?.business_card !== false) {
      // setShoppingCart(prevCart => ({...prevCart, business_card: 169}));
      handleChange({'name': 'shoppingCart.business_card', 'value': 99});
    } else {
      // setShoppingCart(prevCart => ({...prevCart, business_card: 0}));
      handleChange({'name': 'shoppingCart.business_card', 'value': 0});
    }
  }, [company.form?.upsell?.business_card]);

  useEffect(() => {
    if(company.form?.upsell?.domain_name_protection && company.form?.upsell?.domain_name_protection !== false) {
      // setShoppingCart(prevCart => ({...prevCart, domain_name_protection: 169}));
      handleChange({'name': 'shoppingCart.domain_name_protection', 'value': 199});
    } else {
      // setShoppingCart(prevCart => ({...prevCart, domain_name_protection: 0}));
      handleChange({'name': 'shoppingCart.domain_name_protection', 'value': 0});
    }
  }, [company.form?.upsell?.domain_name_protection]);

  useEffect(() => {
    if(company.form?.upsell?.computer_scientict && company.form?.upsell?.computer_scientict !== false) {
      // setShoppingCart(prevCart => ({...prevCart, computer_scientict: 169}));
      handleChange({'name': 'shoppingCart.computer_scientict', 'value': 399});
    } else {
      // setShoppingCart(prevCart => ({...prevCart, computer_scientict: 0}));
      handleChange({'name': 'shoppingCart.computer_scientict', 'value': 0});
    }
  }, [company.form?.upsell?.computer_scientict]);

  useEffect(() => {
    if(company.form?.upsell?.mail_signature && company.form?.upsell?.mail_signature !== false) {
      // setShoppingCart(prevCart => ({...prevCart, mail_signature: 169}));
      handleChange({'name': 'shoppingCart.mail_signature', 'value': 99});
    } else {
      // setShoppingCart(prevCart => ({...prevCart, mail_signature: 0}));
      handleChange({'name': 'shoppingCart.mail_signature', 'value': 0});
    }
  }, [company.form?.upsell?.mail_signature]);


  useEffect(() => {
    if(!company.hubspot_product?.subscriptions?.accounting && company.form?.transaction_id) {
      // const path = 'hubspot_product.subscriptions.accounting';
      updateHubspotLineItems(products.subscriptions.annually.accounting.hs_product_id, company.shoppingCart?.accounting, 'hubspot_product.subscriptions.accounting', 'form.accounting.contract');
    }
    if(!company.hubspot_product?.one_off?.legal_pack && company.form?.legal_pack && company.form?.transaction_id) {
      // const path = 'hubspot_product.subscriptions.accounting';
      switch (company.form?.legal_pack) {
        case "Premium & Réussite":
          updateHubspotLineItems(products.one_off.legal_pack[0].hs_product_id, company.shoppingCart?.legal_pack, 'hubspot_product.one_off.legal_pack', 'form.legal_pack');
          break;
        case "Standard":
          updateHubspotLineItems(products.one_off.legal_pack[1].hs_product_id, company.shoppingCart?.legal_pack, 'hubspot_product.one_off.legal_pack', 'form.legal_pack');
          break;
        case "Débutant & Seul":
          updateHubspotLineItems(products.one_off.legal_pack[2].hs_product_id, company.shoppingCart?.legal_pack, 'hubspot_product.one_off.legal_pack', 'form.legal_pack');
          break;
        default:
          break;
      }
    }
  }, []);

  //
  // Shopping cart updated
  //
  useEffect(() => {
    switch (company.form?.legal_pack) {
      case "Premium & Réussite":
        updateHubspotLineItems(products.one_off.legal_pack[0].hs_product_id, company.shoppingCart?.legal_pack, 'hubspot_product.one_off.legal_pack', 'form.legal_pack');
        break;
      case "Standard":
        updateHubspotLineItems(products.one_off.legal_pack[1].hs_product_id, company.shoppingCart?.legal_pack, 'hubspot_product.one_off.legal_pack', 'form.legal_pack');
        break;
      case "Débutant & Seul":
        updateHubspotLineItems(products.one_off.legal_pack[2].hs_product_id, company.shoppingCart?.legal_pack, 'hubspot_product.one_off.legal_pack', 'form.legal_pack');
        break;
      default:
        break;
    }
  }, [company.shoppingCart?.legal_pack]);

  useEffect(() => {
    const legal_statut_excluded = ["Auto-entrepreneur", "LMNP"];

    if(!legal_statut_excluded.includes(company.form?.legal_statuts)) {
      setCompany({...company, shoppingCart: {...company.shoppingCart, court_fees: 259.39}});
    } else {
      const court_fees = company.form?.legal_statuts === "Auto-entrepreneur" ? 69 : 99;
      setCompany({...company, shoppingCart: {...company.shoppingCart, court_fees: court_fees}});
    }
  }, [company.hubspot_product?.one_off?.legal_pack?.line_item, company.form?.legal_statuts]);

  useEffect(() => {
    if(company.hubspot_product?.one_off?.legal_pack?.line_item) {
      updateLineItem(company.hubspot_product?.one_off?.legal_pack?.line_item, {"libelle_frais_de_debours": "Frais de publication et d'immatriculation", "frais_de_debours": company.shoppingCart?.court_fees})
    }
  }, [company.hubspot_product?.one_off?.legal_pack?.line_item, company.form?.legal_statuts, company.shoppingCart?.court_fees]);


  useEffect(() => {
    // console.log(`Shopping cart (accounting) updated ${company.shoppingCart?.accounting}`);
    updateHubspotLineItems(products.subscriptions.annually.accounting.hs_product_id, company.shoppingCart?.accounting, 'hubspot_product.subscriptions.accounting', 'form.accounting.contract');
  }, [company.shoppingCart?.accounting]);
  useEffect(() => {
    // console.log(`Shopping cart (general_meeting) updated ${company.shoppingCart?.general_meeting}`);
    updateHubspotLineItems(products.subscriptions.annually.ag.hs_product_id, company.shoppingCart?.general_meeting, 'hubspot_product.subscriptions.ag', 'form.accounting.contract');
  }, [company.shoppingCart?.general_meeting]);
  useEffect(() => {
    // console.log(`Shopping cart (kbis_by_post) updated ${company.shoppingCart?.kbis_by_post}`);
    updateHubspotLineItems(products.one_off.kbis_by_post.hs_product_id, company.shoppingCart?.kbis_by_post, 'hubspot_product.one_off.kbis_by_post', 'form.upsell.kbis_by_post');
  }, [company.shoppingCart?.kbis_by_post]);
  useEffect(() => {
    // console.log(`Shopping cart (register_your_trademark) updated ${company.shoppingCart?.register_your_trademark}`);
    updateHubspotLineItems(products.one_off.register_your_trademark.hs_product_id, company.shoppingCart?.register_your_trademark, 'hubspot_product.one_off.register_your_trademark', 'form.upsell.register_your_trademark');
  }, [company.shoppingCart?.register_your_trademark]);
  useEffect(() => {
    // console.log(`Shopping cart (domiciliation) updated ${company.shoppingCart?.domiciliation}`);
    updateHubspotLineItems(products.one_off.domiciliation.hs_product_id, 0, 'hubspot_product.one_off.domiciliation', 'form.domiciliation');
  }, [company.shoppingCart?.domiciliation]);
  useEffect(() => {
    // console.log(`Shopping cart (bank) updated ${company.shoppingCart?.bank}`);
    updateHubspotLineItems(products.one_off.bank.hs_product_id, 0, 'hubspot_product.one_off.bank', 'form.bank');
  }, [company.shoppingCart?.bank]);
  useEffect(() => {
    // console.log(`Shopping cart (bylaws_word) updated ${company.shoppingCart?.bylaws_word}`);
    updateHubspotLineItems(products.one_off.bylaws_word.hs_product_id, company.shoppingCart?.bylaws_word, 'hubspot_product.one_off.bylaws_word', 'form.upsell.bylaws_word');
  }, [company.shoppingCart?.bylaws_word]);
  useEffect(() => {
    // console.log(`Shopping cart (canvassing) updated ${company.shoppingCart?.canvassing}`);
    updateHubspotLineItems(products.one_off.canvassing.hs_product_id, company.shoppingCart?.canvassing, 'hubspot_product.one_off.canvassing', 'form.upsell.canvassing');
  }, [company.shoppingCart?.canvassing]);
  useEffect(() => {
    // console.log(`Shopping cart (logo) updated ${company.shoppingCart?.logo}`);
    updateHubspotLineItems(products.one_off.logo.hs_product_id, company.shoppingCart?.logo, 'hubspot_product.one_off.logo', 'form.upsell.logo');
  }, [company.shoppingCart?.logo]);
  useEffect(() => {
    // console.log(`Shopping cart (brand_identity) updated ${company.shoppingCart?.brand_identity}`);
    updateHubspotLineItems(products.one_off.brand_identity.hs_product_id, company.shoppingCart?.brand_identity, 'hubspot_product.one_off.brand_identity', 'form.upsell.brand_identity');
  }, [company.shoppingCart?.brand_identity]);
  useEffect(() => {
    // console.log(`Shopping cart (business_card) updated ${company.shoppingCart?.business_card}`);
    updateHubspotLineItems(products.one_off.business_card.hs_product_id, company.shoppingCart?.business_card, 'hubspot_product.one_off.business_card', 'form.upsell.business_card');
  }, [company.shoppingCart?.business_card]);
  useEffect(() => {
    // console.log(`Shopping cart (flyers) updated ${company.shoppingCart?.flyers}`);
    updateHubspotLineItems(products.one_off.flyers.hs_product_id, company.shoppingCart?.flyers, 'hubspot_product.one_off.flyers', 'form.upsell.flyers');
  }, [company.shoppingCart?.flyers]);
  useEffect(() => {
    // console.log(`Shopping cart (domain_name_protection) updated ${company.shoppingCart?.domain_name_protection}`);
    updateHubspotLineItems(products.one_off.domain_name_protection.hs_product_id, company.shoppingCart?.domain_name_protection, 'hubspot_product.one_off.domain_name_protection', 'form.upsell.domain_name_protection');
  }, [company.shoppingCart?.domain_name_protection]);
  useEffect(() => {
    // console.log(`Shopping cart (computer_scientict) updated ${company.shoppingCart?.computer_scientict}`);
    updateHubspotLineItems(products.one_off.computer_scientict.hs_product_id, company.shoppingCart?.computer_scientict, 'hubspot_product.one_off.computer_scientict', 'form.upsell.computer_scientict');
  }, [company.shoppingCart?.computer_scientict]);
  useEffect(() => {
    // console.log(`Shopping cart (mail_signature) updated ${company.shoppingCart?.mail_signature}`);
    updateHubspotLineItems(products.one_off.mail_signature.hs_product_id, company.shoppingCart?.mail_signature, 'hubspot_product.one_off.mail_signature', 'form.upsell.mail_signature');
  }, [company.shoppingCart?.mail_signature]);
  useEffect(() => {
    // console.log(`Shopping cart (community_management) updated ${company.shoppingCart?.community_management}`);
    updateHubspotLineItems(products.one_off.community_management.hs_product_id, company.shoppingCart?.community_management, 'hubspot_product.one_off.community_management', 'form.upsell.community_management');
    // Créer le paiement prix complet et décaler la date de paiement de +30 jours
  }, [company.shoppingCart?.community_management]);
  useEffect(() => {
    updateHubspotLineItems(products.one_off.deregistration.hs_product_id, company.shoppingCart?.deregistration, 'hubspot_product.one_off.deregistration', 'form.upsell.deregistration');
  }, [company.shoppingCart?.deregistration]);

  const updateCompanyValue = (path, value) => {
    setCompany(prevCompany => {
      const newCompany = { ...prevCompany };
      setValue(newCompany, path, value);
      return newCompany;
    });
  };

  async function updateHubspotLineItems(id, item_price, path, company_path) {
    // console.log('Line items update in progress', shoppingCart);
    const hubspot_product = getValueByPath(company, path);
    const product_value = getValueByPath(company, company_path);
    const prev_product = (typeof hubspot_product !== 'undefined') ? hubspot_product.product : null;
    const prev_line_item = (typeof hubspot_product !== 'undefined') ? hubspot_product.line_item : null;
    if(getPathIndex(window.location.pathname) !== 0) {
      if(company.form?.transaction_id && ['hubspot_product.subscriptions.ag'].includes(path)) {
        if(product_value === 'annually') {
          if(company.hubspot_product?.subscriptions?.ag?.line_item) {
            if(company.hubspot_product.subscriptions.ag.line_item === null) {
              // console.log(`Ajout des AG offerte`);
              // await addLineItem(id, item_price, path);
            } else {
              // console.log(`Passage des AG payante en offerte`);
              // await updateLineItem(company.hubspot_product.subscriptions.ag.line_item, {"price": 0});
            }
          }
        } else {
          // if(company.hubspot_product.subscriptions.ag.line_item === null) {
          //   console.log(`Ajout des AG payante`);
          //   if(id !== parseInt(prev_product)) {
          //     // await addLineItem(id, item_price, path);
          //   }
          // } else {
          //   console.log(`Passage des AG offerte en payante`);
          //   // await updateLineItem(company.hubspot_product.subscriptions.ag.line_item, {"price": 119});
          // }
        }
      } else if(company.form?.transaction_id && ['hubspot_product.subscriptions.accounting'].includes(path)) {
        if(product_value === 'annually') {
          // console.log('window.location.pathname', window.location.pathname);
          if(company.hubspot_product.subscriptions.accounting === undefined || company.hubspot_product.subscriptions.accounting.line_item === null) {
            // console.log(`Ajout de la comptabilité annuelle`);
            if(id !== parseInt(prev_product)) {
              console.log('Add line item 1');
              await addLineItem(id, item_price, path);
            }
          } else {
            // console.log(`Passage de la comptabilité mensuelle en annuelle`);
            console.log('Add line item 2');
            await deleteLineItem(company.hubspot_product.subscriptions.accounting.line_item, path);
            await addLineItem(id, item_price, path);
          }
        } else {
          if(company.hubspot_product?.subscriptions?.accounting?.line_item === null || company.hubspot_product?.subscriptions?.accounting?.line_item === undefined) {
            // console.log(`Ajout de la comptabilité mensuelle`);
            console.log('Add line item 3');
            await addLineItem(products.subscriptions.monthly.accounting.hs_product_id, item_price, path);
          }
        }
      } else {
        // console.log(company.form?.transaction_id, id, prev_product, product_value);
        if(company.form?.transaction_id && id !== parseInt(prev_product) && (product_value || ["Premium & Réussite", "Standard", "Débutant & Seul"].includes(product_value))) {
          if(["3295289071", "3294325729", "3295289074"].includes(prev_product)) {
            // console.log(`Suppression du précédent pack legal nécessaire : ${prev_product}`);
            await deleteLineItem(prev_line_item, path);
          }
          // console.log('Ajout du produit en même temps que les legalpack', id, parseInt(prev_product), product_value, id !== parseInt(prev_product));
          console.log('Add line item 5');
          await addLineItem(id, item_price, path);
        } else {
          // console.log(company.form?.transaction_id && prev_product && (product_value === false || product_value === ''), company.form?.transaction_id, prev_product, product_value)
          if(company.form?.transaction_id && prev_product && (product_value === false || product_value === '')) {
            // console.log('Suppression nécessaire du produit', prev_product, prev_line_item);
            await deleteLineItem(prev_line_item, path);
          } else if(company.form?.transaction_id && prev_product && id !== parseInt(prev_product) && product_value) {
            // console.log('Ajout nécessaire du produit en else if', prev_product, prev_line_item, product_value, id);
            console.log('Add line item 6');
            await addLineItem(id, item_price, path);
          } else { 
            // console.log('Aucun ID de transaction renseigné ou aucun produit');
          }
        }
      }
    }
  }

  async function addLineItem(id, item_price, path) {
    const data = {
      deal_id: company.form?.transaction_id,
      hs_product_id: id,
      price: item_price
    };
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify(data);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    try {
      console.log(`Ajout du produit ${path}.product`)
      const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/hubspot/line_items/create`, requestOptions);
      const result = await response.json();
      updateCompanyValue(`${path}.product`, result.line_item.response.properties.hs_product_id);
      updateCompanyValue(`${path}.line_item`, result.line_item.response.properties.hs_object_id);
    } catch (error) {
      console.error(error);
    };

  }

  async function deleteLineItem(prev_line_item, path) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "line_item_id": prev_line_item
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/hubspot/line_items/delete`, requestOptions);
      const result = await response.text();
      // console.log(result);
      updateCompanyValue(`${path}.product`, null);
      updateCompanyValue(`${path}.line_item`, null);  
    } catch (error) {
      console.error(error);
    };
  }

  async function updateLineItem(prev_line_item, properties) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "line_item_id": prev_line_item,
      "properties": properties
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/hubspot/line_items/update`, requestOptions);
      const result = await response.json();
      // console.log(result);
    } catch (error) {
      console.error(error);
    };
  }

  function setValue(obj, path, value) {
    const keys = path.split('.');
    let current = obj;
    for (let i = 0; i < keys.length - 1; i++) {
      if (!(keys[i] in current)) {
        current[keys[i]] = {};
      }
      current = current[keys[i]];
    }
    current[keys[keys.length - 1]] = value;
  }

  useEffect(() => {
    if(company.form?.transaction_id) {
      const { email, firstname, lastname, phone } = company.form?.contact;
      // console.log(JSON.stringify({
      //   contact_id: company.form?.contact_id,
      //   firstname: firstname,
      //   lastname: lastname,
      //   phone: phone
      // }))
      fetch(`${process.env.REACT_APP_NODE_BACKEND_DOMAIN}api/hubspot/contact/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          contact_id: company.form?.contact_id,
          firstname: firstname,
          lastname: lastname,
          phone: phone
        })
      })
        .then(response => response.json())
        .then(data => {
          // Handle the response from the backend
          // console.log(data);
        })
        .catch(error => {
          // Handle any errors
          console.error(error);
        });
    }
  }, [company.form?.contact]);

  return (
    <Router>
      <UserContext.Provider value={{ company, setCompany, handleChange, shoppingCart, setShoppingCart, updateShoppingCart, updateCompanyValue, getPathIndex }}>
        <Helmet>
          <title>Création - Comptastar</title>
          <meta name="description" content="Formulaire d'inscription à Comptastar"/>
          <meta property="og:title" content='Création - Comptastar' />
          <meta property="og:description" content="Formulaire d'inscription à Comptastar" />
          <meta property="og:url" content="https://creation.comptastar.fr" />
          <meta property="og:type" content="website" />
          <link href="/public/favicon.ico" rel="shortcut icon" type="image/x-icon"/>
          <link href="/public/favicon.ico" rel="apple-touch-icon"/>
        </Helmet>
        <div className='super-global'>
          <NewCompany />
          <LastPage />
          <NavBar />
          <div className='super global'>
            <ProgressBar currentStep={currentStep}/>
            <RouterComponent setCurrentStep={ setCurrentStep }/>
            <Analytics/>
            <SpeedInsights/>
          </div>
        </div>
      </UserContext.Provider>
    </Router>
  );
}

export default App;